<template>
  <div class="mx-auto relative ml-2.5">
    <div class="font-semibold flex-1 h-screen">
      <Navbar class="px-10" />
      <div id="detail-content" class="p-7 text-black" v-if="data">
        <div v-if="data" class="font-semibold text-xl flex gap-2 items-center">
          <img
            :src="require('@/assets/images/button-back.svg')"
            class="h-4"
            @click.prevent="goBack()"
          />
          <div>
            <p class="text-2xl">{{ data.name }}</p>
          </div>
        </div>
        <div class="flex gap-4">
          <div class="w-1/4 h-full grid gap-4 py-6 justify-center">
            <img :src="data.profile_picture" class="w-72" />
            <div
              class="w-full border border-gray-200 rounded-3xl uppercase font-semibold text-center py-2 text-gray-500 text-xl"
            >
              {{ data.user_type }}
            </div>
            <div>
              <div class="font-semibold text-gray-700 text-lg">
                Added on Texperia
              </div>
              <div class="font-normal text-gray-500 text-base">
                {{ data.added_on }}
              </div>
            </div>
            <div>
              <div class="font-semibold text-gray-700 text-lg">Last login</div>
              <div class="font-normal text-base text-gray-500">
                <div v-if="data.last_login">
                  {{ data.last_login }}
                </div>
                <div v-else>Not logged in as yet</div>
              </div>
            </div>
          </div>
          <div class="w-3/4 border border-gray-200 rounded-xl">
            <div class="flex justify-between p-6">
              <div class="flex gap-6 items-center">
                <div class="text-5xl font-bold">
                  {{ data.name }}
                </div>
                <div>
                  <div v-if="data.profile_link">
                    <a
                      :href="data.profile_link"
                      target="_blank"
                      class="bg-white border border-nColorBlue px-4 py-1 font-rajdhani w-full cursor-pointer text-nColorBlue font-normal rounded hover:bg-nColorBlue hover:text-white"
                    >
                      View Profile
                    </a>
                  </div>
                  <div v-else>
                    <div
                      class="bg-white border border-gray-300 px-4 py-1 font-rajdhani w-full text-gray-300 font-normal rounded cursor-default"
                    >
                      View Profile
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border-t border-b border-gray-200">
              <div class="flex gap-4 p-6">
                <div class="w-1/4">
                  <div class="font-semibold text-gray-700 text-lg">
                    Personal Email
                  </div>
                  <div class="font-normal text-base text-gray-500">
                    {{ data.personal_email }}
                  </div>
                </div>
                <div class="w-1/4">
                  <div class="font-semibold text-gray-700 text-lg">
                    Date of offer acceptance
                  </div>
                  <div class="font-normal text-base text-gray-500">
                    {{ data.doa }}
                  </div>
                </div>
                <div class="w-1/4">
                  <div class="font-semibold text-gray-700 text-lg">
                    Official Email
                  </div>
                  <div class="font-normal text-base text-gray-500">
                    <div v-if="data.official_email">
                      {{ data.official_email }}
                    </div>
                    <div v-else>Not available</div>
                  </div>
                </div>
                <div class="w-1/4">
                  <div class="font-semibold text-gray-700 text-lg">
                    Mobile Number
                  </div>
                  <div class="font-normal text-base text-gray-500">
                    <div v-if="data.mobile">
                      {{ data.mobile }}
                    </div>
                    <div v-else>Not available</div>
                  </div>
                </div>
              </div>
              <div class="flex gap-4 px-6 pt-3 pb-6">
                <div class="w-1/4">
                  <div class="font-semibold text-gray-700 text-lg">
                    Date of joining
                  </div>
                  <div class="font-normal text-base text-gray-500">
                    {{ data.doj }}
                  </div>
                </div>
                <div class="w-1/4">
                  <div class="font-semibold text-gray-700 text-lg">
                    Birthday
                  </div>
                  <div class="font-normal text-base text-gray-500">
                    <div v-if="data.birthday">
                      {{ data.birthday }}
                    </div>
                    <div v-else>Not available</div>
                  </div>
                </div>
                <div class="w-1/4">
                  <div class="font-semibold text-gray-700 text-lg">
                    Onboarding Kit
                  </div>
                  <div class="font-normal text-base text-gray-500">
                    <div v-if="data.exps.onboarding_kit">
                      {{ data.exps.onboarding_kit }}
                    </div>
                    <div v-else>Not available</div>
                  </div>
                </div>
                <div class="w-1/4"></div>
              </div>
            </div>
            <div
              class="grid grid-cols-2 bg-nColorFillGray rounded-b-xl"
              v-if="data.exps.onboarding_kit"
            >
              <div
                v-for="exp in data.exps.experiences"
                :key="exp.meta.id"
                class="bg-white rounded-xl m-2 shadow flex gap-3"
              >
                <div>
                  <img :src="exp.meta.icon" class="rounded-l-xl h-40" />
                </div>
                <div>
                  <div class="pt-2 text-lg">
                    {{ exp.meta.name }}
                  </div>
                  <div class="w-full flex gap-4 mt-6">
                    <div class="flex flex-col items-center w-1/3">
                      <div class="flex justify-center">
                        <Checkmark v-if="exp.flags.is_eligible" />
                        <Crossmark v-else />
                      </div>
                      <h2 class="text-gray-700 text-sm font-semibold mt-2">
                        Eligible
                      </h2>
                    </div>
                    <div class="flex flex-col items-center w-1/3">
                      <div class="flex justify-center">
                        <Checkmark v-if="exp.flags.complete" />
                        <Crossmark v-else />
                      </div>
                      <h2 class="text-gray-700 text-sm font-semibold mt-2">
                        Participated
                      </h2>
                    </div>
                    <div class="flex flex-col items-center w-1/3">
                      <div class="flex justify-center">
                        <Checkmark v-if="exp.flags.feedback" />
                        <Crossmark v-else />
                      </div>
                      <h2
                        class="text-gray-700 text-sm font-semibold mt-2 text-center"
                      >
                        Feedback
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar/Plain";
import { format } from "date-fns";
import Checkmark from "@/components/Icons/Checkmark.vue";
import Crossmark from "@/components/Icons/Crossmark.vue";

export default {
  name: "UserDetail",
  components: { Navbar, Checkmark, Crossmark },
  data() {
    return {
      userId: this.$route.params.id,
      data: null,
      format: format,
    };
  },
  mounted() {
    ApiService.get(apiResource.users.getUserDetails, {
      user_id: this.userId,
    }).then(({ data }) => {
      this.data = data.data;
    });
  },
  methods: {
    goBack() {
      var backPage = window.history.state.back;
      if (backPage) {
        this.$router.back();
      } else {
        this.$router.push({
          name: "Dashboard",
        });
      }
    },
    formattedDate(date) {
      var processDate = date;
      if (typeof date != "string") {
        processDate = format(date, "dd-MM-yyyy");
      }
      let newDateArr = processDate.split("-");
      const formattedDate = format(
        new Date(newDateArr[2], newDateArr[1] - 1, newDateArr[0]),
        "MMMM do, yyyy"
      );
      return `${formattedDate}`;
    },
  },
  computed: {},
};
</script>

<style scoped lang="postcss"></style>
